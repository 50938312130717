import { useContext, useEffect, useState } from "react";
import { PageTitleContext } from "../contexts/page-title-context";
import { CrumbsContext } from "../contexts/crumbs-context";
import { ApiConsumer } from "../api/ApiConsumer";
import { API_ROUTES } from "../routes/api";
import { useParams } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { OrderItem } from "components/table";
import { CustomerCard } from "components/cards/customer";

export default function OrderDetail() {
    const [order, setOrder] = useState()
    const [open, setOpen] = useState(false)
    let { setTitle } = useContext(PageTitleContext)
    let { setCrumbs } = useContext(CrumbsContext)

    let { id } = useParams();

    function load_data() {
        ApiConsumer.get(API_ROUTES.STORE.ORDERS.DETAIL(id))
            .then(res => {
                setOrder(res?.data);
                setCrumbs([{ 'name': 'Orders', 'link': '/orders' }, {
                    'name': res?.data?.name ?? 'Order Detail',
                    'link': `/order/${id}`
                }])
                console.log('Order Detail: ', res?.data)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        // setTitle('Orders')
        // setCrumbs([{ 'name': 'Orders', 'link': '/orders' }])
        load_data()
    }, [])

    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1" />
            <div className="mt-4 flex md:mt-0 md:ml-4">
                <PlusCircleIcon
                    onClick={() => setOpen(true)}
                    className="h-12 cursor-pointer text-emerald-500 px-4 py-2 text-sm font-medium hover:text-emerald-700" />
            </div>
        </div>
        <div className="grid grid-cols-2 gap-4 grid-cols-[min-content_1fr]">
            {
                <CustomerCard customer={order?.customer} />
            }
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Image</span>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Description
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Qty
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Price
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Total Price
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        order?.line_items.map((item, i) => {
                            return <OrderItem key={i} item={item?.inventory_item} />
                        })
                    }
                </tbody>
            </table>
        </div>
    </>
}
