import {useContext, useEffect, useState} from "react";
import {PageTitleContext} from "../contexts/page-title-context";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {CrumbsContext} from "../contexts/crumbs-context";
import {PlusCircleIcon} from "@heroicons/react/outline";
import {CommerceCard} from "../components/cards/commerce";
import CommerceModal from "../components/modals/commerce-modal";

export default function Suppliers() {
    const [suppliers, setSupplier] = useState([])
    const [open, setOpen] = useState(false)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function fetch() {
        ApiConsumer.get(API_ROUTES.INVENTORY.SUPPLIERS.LIST)
            .then(res => {
                setSupplier(res.data)
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        setTitle('Suppliers')
        setCrumbs([{'name': 'Inventory', 'link': '/inventory'}, {'name': 'Suppliers', 'link': '/inventory/suppliers'}])

        fetch()
    }, [])

    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1"/>
            <div className="mt-4 flex md:mt-0 md:ml-4">
                <PlusCircleIcon
                    onClick={() => setOpen(true)}
                    className="h-12 cursor-pointer text-emerald-500 px-4 py-2 text-sm font-medium hover:text-emerald-700"/>
            </div>
        </div>
        <CommerceModal brand={{}} open={open} setOpen={setOpen} refresh={fetch} url={API_ROUTES.INVENTORY.SUPPLIERS.LIST}
                       detail_url={''}/>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
            {
                suppliers.map((supplier, i) => {
                    return <CommerceCard key={i} entity={supplier} title={'Supplier'}
                                         detail_url={API_ROUTES.INVENTORY.SUPPLIERS.DETAIL(supplier.id)} refresh={fetch}/>
                })
            }
        </div>
    </>
}
