import parse from 'html-react-parser';
import { useEffect } from 'react';
import { useState } from 'react';

export function OrderItem({ item }) {
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        var tempTotal = 0;
        item?.add_on_costs?.forEach(element => {
            tempTotal += parseFloat(element.cost);
        });
        tempTotal = (tempTotal + parseFloat(item?.price)) - parseFloat(item?.discount_amount);
        setTotalPrice(tempTotal);
    }, [])
    //var total = item
    console.log("Item: ", item)
    return <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td class="w-32 p-4">
            <img src={item?.thumbnail} className="h-40 w-72 object-cover rounded" />
        </td>
        <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white">
            {parse(item?.description)}
        </td>
        <td class="px-6 py-4">
            <div class="flex items-center space-x-3">

            </div>
        </td>
        <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white">
            R {item?.price}
        </td>
        <td class="px-6 py-4">
            R {totalPrice}
        </td>
    </tr>
}
