import {PageTitleContext} from "../contexts/page-title-context";
import {CrumbsContext} from "../contexts/crumbs-context";
import {useContext, useEffect, useState} from "react";
import {ApiConsumer} from "../api/ApiConsumer";
import {API_ROUTES} from "../routes/api";
import {BannerCard} from "../components/cards/banner";
import {PlusCircleIcon} from "@heroicons/react/outline";
import BannerModal from "../components/modals/banner-modal";

export default function Banners() {
    const [banners, setBanners] = useState([])
    const [open, setOpen] = useState(false)
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function fetch() {
        ApiConsumer.get(API_ROUTES.ADMINISTRATION.BANNERS.LIST)
            .then(res => setBanners(res.data))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        setTitle('Banners')
        setCrumbs([{'name': 'Administration', 'link': '#'}, {'name': 'Banners', 'link': '/admin/banners'}])

        fetch()
    }, [])

    return <>
        <div className="md:flex md:items-center md:justify-between mb-6">
            <div className="min-w-0 flex-1"/>
            <div className="mt-4 flex md:mt-0 md:ml-4">
                <PlusCircleIcon
                    onClick={() => setOpen(true)}
                    className="h-12 cursor-pointer text-emerald-500 px-4 py-2 text-sm font-medium hover:text-emerald-700"/>
            </div>
        </div>
        <BannerModal setOpen={setOpen} open={open} refresh={fetch} />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {
                banners.map((banner, i) => {
                    return <BannerCard key={i} banner={banner} refesh={fetch}/>
                })
            }
        </div>
    </>
}
