import RichTextEditor from "react-rte";
function RemoveEmptyProps(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}
function BuildFormData(details) {
    let data = new FormData()
    let cleanDetails = RemoveEmptyProps(details)
    let detailsKeyArray = Object.keys(cleanDetails)
    detailsKeyArray.map(key => {
        if (cleanDetails[key])
            data.append(key, cleanDetails[key]);
    })
    return data
}

function ConvertDateToString(date) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ]
    let new_date = new Date(date)
    return `${new_date.getDate()} ${months[new_date.getMonth()]} ${new_date.getFullYear()}`
}
function TextEditorFormatter(value) {
    if (value === null || value === undefined || value === '')
        return RichTextEditor.createEmptyValue()
    return RichTextEditor.createValueFromString(value, 'html');
}
export const useUtils = () => {
    return {BuildFormData, ConvertDateToString, TextEditorFormatter}
}
