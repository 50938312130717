

export function CustomerCard({ customer }) {

    return <div className="w-64 mx-auto bg-[#20354b] rounded-2xl px-8 py-6 shadow-lg">
        <div className="mt-6 w-fit mx-auto">
            <img src="https://cdn.landesa.org/wp-content/uploads/default-user-image.png" className="rounded-full w-28 " alt="profile picture" srcSet="" />
        </div>

        <div className="mt-8 text-center">
            <h2 className="text-white font-bold text-l tracking-wide">{customer?.first_name} {customer?.last_name}</h2>
        </div>

        <div className="text-emerald-400 mt-2 text-center">
            Customer
        </div>
        <p className="text-emerald-400 mt-2.5 text center" >
            {customer?.bio}
        </p>
    </div>
}
